export const materialColors: Record<string, string[]> = {
  "Cardboard, paper": [
    "hsl(32, 90%, 35%)",
    "hsl(32, 90%, 45%)",
    "hsl(32, 90%, 65%)",
  ],
  "Canvas, Polycotton": [
    "hsl(32, 50%, 35%)",
    "hsl(32, 50%, 45%)",
    "hsl(32, 50%, 55%)",
    "hsl(32, 50%, 65%)",
  ],
  Hemp: ["hsl(96, 80%, 13%)", "hsl(96, 80%, 23%)", "hsl(96, 80%, 33%)"],
  "Grass, straw": ["hsl(96, 90%, 23%)", "hsl(96, 90%, 33%)"],
  Bamboo: [
    "hsl(96, 60%, 43%)",
    "hsl(96, 60%, 53%)",
    "hsl(96, 60%, 63%)",
    "hsl(96, 60%, 84%)",
  ],
  Timber: [
    "hsl(96, 20%, 43%)",
    "hsl(96, 20%, 48%)",
    "hsl(96, 20%, 53%)",
    "hsl(96, 20%, 58%)",
    "hsl(96, 20%, 63%)",
    "hsl(96, 20%, 68%)",
    "hsl(96, 20%, 73%)",
  ],
  Bitumen: ["hsl(328, 47%, 42%)", "hsl(328, 47%, 52%)", "hsl(328, 47%, 62%)"],
  Rubber: ["hsl(328, 37%, 62%)", "hsl(328, 37%, 72%)"],
  "Plastic, polymer": [
    "hsl(328, 87%, 43%)",
    "hsl(328, 87%, 48%)",
    "hsl(328, 87%, 53%)",
    "hsl(328, 87%, 58%)",
    "hsl(328, 87%, 63%)",
    "hsl(328, 87%, 68%)",
    "hsl(328, 87%, 73%)",
    "hsl(328, 87%, 78%)",
    "hsl(328, 87%, 83%)",
  ],
  Sand: ["hsl(41, 95%, 36%)", "hsl(41, 95%, 46%)", "hsl(41, 95%, 56%)"],
  "Earth, soil, mud": [
    "hsl(51, 85%, 36%)",
    "hsl(51, 85%, 46%)",
    "hsl(51, 85%, 56%)",
    "hsl(51, 85%, 66%)",
    "hsl(51, 85%, 76%)",
  ],
  Clay: [
    "hsl(51, 70%, 36%)",
    "hsl(51, 70%, 41%)",
    "hsl(51, 70%, 46%)",
    "hsl(51, 70%, 51%)",
    "hsl(51, 70%, 66%)",
    "hsl(51, 70%, 71%)",
  ],
  Gypsum: ["hsl(51, 60%, 36%)", "hsl(51, 60%, 46%)", "hsl(51, 60%, 56%)"],
  Lime: ["hsl(51, 40%, 36%)", "hsl(51, 40%, 46%)", "hsl(51, 40%, 56%)"],
  Stone: [
    "hsl(51, 25%, 61%)",
    "hsl(51, 25%, 66%)",
    "hsl(51, 25%, 71%)",
    "hsl(51, 25%, 76%)",
  ],
  Cement: [
    "hsl(51, 15%, 36%)",
    "hsl(51, 15%, 46%)",
    "hsl(51, 15%, 56%)",
    "hsl(51, 15%, 66%)",
  ],
  Concrete: [
    "hsl(51, 5%, 36%)",
    "hsl(51, 5%, 46%)",
    "hsl(51, 5%, 52%)",
    "hsl(51, 5%, 60%)",
    "hsl(51, 5%, 66%)",
    "hsl(51, 5%, 76%)",
    "hsl(51, 5%, 86%)",
  ],
  Other: ["hsl(0, 0%, 25%)"],
  Steel: [
    "hsl(206, 83%, 37%)",
    "hsl(206, 83%, 41%)",
    "hsl(206, 83%, 45%)",
    "hsl(206, 83%, 49%)",
    "hsl(206, 83%, 53%)",
    "hsl(206, 83%, 57%)",
    "hsl(206, 83%, 61%)",
    "hsl(206, 83%, 65%)",
    "hsl(206, 83%, 69%)",
    "hsl(206, 83%, 73%)",
    "hsl(206, 83%, 75%)",
    "hsl(206, 83%, 78%)",
    "hsl(206, 83%, 82%)",
    "hsl(206, 83%, 87%)",
  ],
  "Steel/brass fixture, fixing ": [
    "hsl(206, 43%, 57%)",
    "hsl(206, 43%, 62%)",
    "hsl(206, 43%, 67%)",
    "hsl(206, 43%, 77%)",
    "hsl(206, 43%, 87%)",
  ],
  Aluminium: [
    "hsl(206, 33%, 57%)",
    "hsl(206, 33%, 67%)",
    "hsl(206, 33%, 77%)",
    "hsl(206, 33%, 87%)",
  ],
  Glass: [
    "hsl(59, 100%, 32%)",
    "hsl(59, 100%, 42%)",
    "hsl(59, 100%, 52%)",
    "hsl(59, 100%, 62%)",
  ],
};
