var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('v-tabs',{staticClass:"fixed-tabs-bar",attrs:{"centered":"","background-color":"white","grow":"","show-arrows":true,"elevation":"2","hide-slider":""},model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},[_vm._l((_vm.menuItems),function(item){return [_c('v-tab',{key:item.to,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.text)+" ")],1)]})],2),(_vm.filteredItems)?_c('v-card-text',[(_vm.selectedTitle)?_c('v-card-title',[_c('h2',{staticClass:"text-h6 font-weight-medium",domProps:{"innerHTML":_vm._s(_vm.selectedTitle)}})]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"dense":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.value`,fn:function(props){return [_c('span',{attrs:{"title":props.item._id}},[_vm._v(_vm._s(_vm._f("formatNumberGhgRef")(props.item.value)))])]}},{key:`item.source`,fn:function(props){return [(props.item.source)?_c('span',[(
              typeof props.item.source === 'object' &&
              props.item.source.length
            )?[_c('ul',_vm._l((props.item.source),function(source,$key){return _c('ol',{key:$key},[_c('a',{attrs:{"href":source,"target":"_blank"}},[_vm._v(_vm._s(source))])])}),0)]:[_c('a',{attrs:{"href":props.item.source,"target":"_blank"}},[_vm._v(_vm._s(props.item.source))])]],2):_vm._e(),(props.item.ref)?_c('info-tooltip',_vm._b({},'info-tooltip',{
            'open-on-click': true,
            'open-on-focus': true,
            'open-on-hover': true,
            'close-delay': 0,
          },false),[_c('span',[_vm._v(" "+_vm._s(props.item.ref)+" ")])]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.DomesticSolidWaste === _vm.selectedTab)?_c('div',[_c('GHGMixedBiowaste'),_c('GHGMixedNonBiowaste')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }